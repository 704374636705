import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "bulma-helpers/css/bulma-helpers.min.css"
import "../styles/all.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Link } from "gatsby"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexPageQuery {
      logo: file(relativePath: { eq: "cavalry-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      basketball: file(relativePath: { eq: "basketball.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      court: file(relativePath: { eq: "basketball-court.png" }) {
        childImageSharp {
          fixed(height: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      jersey: file(relativePath: { eq: "number-8-shirt.png" }) {
        childImageSharp {
          fixed(height: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      allContentfulHomePageAnnouncementRegistration {
        edges {
          node {
            showAnnouncement
            textForLink
          }
        }
      }
      allContentfulHomePageAnnouncementGeneral {
        edges {
          node {
            announcement
            showAnnouncement
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <div>
        <section className="hero">
          <div className="hero-body has-text-centered">
            <div className="container" style={{ maxWidth: "500px" }}>
              <Img
                fluid={data.logo.childImageSharp.fluid}
                alt="Cavalry Basketball"
              />
            </div>
          </div>
        </section>
        {/*<div className="columns is-mobile has-margin-bottom-35">*/}
        {/*  <div className="column is-10-mobile is-offset-1-mobile is-6-desktop is-offset-3-desktop">*/}
        {/*    <article className="message is-warning has-margin-top-25">*/}
        {/*      <div className="message-body is-size-5">*/}
        {/*        <strong>NO PRACTICE TONIGHT! (1/22/20)</strong>*/}
        {/*  make as title in Contentful */}
        {/*        <br />*/}
        {/*        Whenever school is cancelled due to inclement weather, Cavalry*/}
        {/*        must also cancel all practices and events for the day and*/}
        {/*        evening. Enjoy the night off and stay warm.*/}
        {/*      </div>*/}
        {/*    </article>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="has-background-white-ter">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="content">
                <div className="has-margin-top-15 has-margin-15-mobile">
                  <div className="tile">
                    <h1 className="title has-padding-bottom-5 why-cav">
                      Cavalry Basketball
                    </h1>
                  </div>
                  <div className="tile">
                    <h3 className="subtitle">
                      Cavalry Basketball is home to one of the most dynamic
                      youth basketball programs in the midwest, focused on
                      skills development and building strong character and
                      sportsmanship in athletes up to 8th grade.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {data.allContentfulHomePageAnnouncementRegistration.edges[0].node
          .showAnnouncement && (
          <div className="columns is-mobile">
            <div className="column is-10-mobile is-offset-1-mobile is-6-desktop is-offset-3-desktop">
              <article className="message is-info has-margin-top-25">
                <div className="message-body is-size-5">
                  <Link to="/registration">CLICK HERE</Link>{" "}
                  {
                    data.allContentfulHomePageAnnouncementRegistration.edges[0]
                      .node.textForLink
                  }
                </div>
              </article>
            </div>
          </div>
        )}
        {data.allContentfulHomePageAnnouncementGeneral.edges[0].node
          .showAnnouncement && (
          <div className="columns is-mobile">
            <div className="column is-10-mobile is-offset-1-mobile is-8-desktop is-offset-2-desktop">
              <article className="message is-info has-margin-top-25">
                <div className="message-body is-size-5">
                  {
                    data.allContentfulHomePageAnnouncementGeneral.edges[0].node
                      .announcement
                  }
                </div>
              </article>
            </div>
          </div>
        )}
        <div className="columns is-mobile">
          <div className="column is-12 section-icon">
            <Img
              fixed={data.court.childImageSharp.fixed}
              alt="Cavalry Basketball"
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">
              <div className="column is-12">
                <p className="is-size-4">
                  The Cavalry Basketball Program is a skills based program.
                  Academies for 3rd-8th graders kick off the program each fall.
                  These academies practice ball handing, passing, footwork,
                  lay-ups, and shooting. The beginning concepts of offense and
                  defense are also introduced.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="columns is-mobile">
          <div className="column is-12 section-icon">
            <Img
              fixed={data.basketball.childImageSharp.fixed}
              alt="Cavalry Basketball"
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">
              <div className="column is-12">
                <p className="is-size-4">
                  At the conclusion of the fall academies, competitive teams are
                  formed. Cavalry offers these competitive teams for 4th-8th
                  grades. Fourth grade teams are divided evenly. Fifth through
                  eighth grade teams are divided based on ability. Practices
                  begin in December. The boys compete in the Predator League
                  based out of OSA in Omaha. The girls compete in the Conqueror
                  League based out of Bellevue. Both leagues start approximately
                  the first weekend of January and end toward the end of
                  February. Cavalry teams also play into 2-3 extra tournaments
                  which typically extend into March. In the spring, the Cavalry
                  offers similar skill work for Kindergarten through 3rd grades.
                </p>
              </div>
              {/*<Features gridItems={intro.blurbs} />*/}
              {/*<div className="columns">*/}
              {/*<div className="column is-12">*/}
              {/*  <h3 className="has-text-weight-semibold is-size-2">*/}
              {/*    Announcements*/}
              {/*  </h3>*/}
              {/*  /!*<BlogRoll />*!/*/}
              {/*  <div className="column is-12 has-text-centered">*/}
              {/*    <Link className="btn" to="/blog">*/}
              {/*      Read more*/}
              {/*    </Link>*/}
              {/*  </div>*/}
              {/*  /!*</div>*!/*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        {/*<div className="columns is-mobile">*/}
        {/*  <div className="column is-12 section-icon">*/}
        {/*    <Img*/}
        {/*      fixed={data.jersey.childImageSharp.fixed}*/}
        {/*      alt="Cavalry Basketball"*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="columns">*/}
        {/*  <div className="column is-10 is-offset-1">*/}
        {/*    <div className="content">*/}
        {/*      <div className="column is-12 has-text-centered">*/}
        {/*        <a*/}
        {/*          className="button is-primary is-large"*/}
        {/*          href="https://rivalryapparel.com/shop?olsPage=t%2Fcavalry-basketball&page=1"*/}
        {/*          target="_blank"*/}
        {/*          rel="noopener noreferrer"*/}
        {/*        >*/}
        {/*          Get Your Cavalry Gear!*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </Layout>
  )
}

export default IndexPage
